import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { SharedModule } from './core/shared/shared.module';
import { LayoutComponent } from './layout/layout.component';
import { MassagesComponent } from './pages/massages/massages.component';
import { MassageComponent } from './pages/massage/massage.component';
import { FormationsComponent } from './pages/formations/formations.component';
import { FormationComponent } from './pages/formation/formation.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CarteCadeauComponent } from './pages/carte-cadeau/carte-cadeau.component';
import { HttpClientModule } from '@angular/common/http';
import { AboutComponent } from './pages/about/about.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MarkdownToHtmlModule } from 'markdown-to-html-pipe';
import { MatomoModule } from 'ngx-matomo';

registerLocaleData(localeFr, 'fr');
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HomeComponent,
    MassagesComponent,
    MassageComponent,
    FormationsComponent,
    FormationComponent,
    ContactComponent,
    CarteCadeauComponent,
    AboutComponent,
  ],
  imports: [
    MatomoModule.forRoot({
      scriptUrl: 'https://analytics.soinsenergetiques-bethune.fr/matomo.js',
      trackers: [
        {
          trackerUrl: 'https://analytics.soinsenergetiques-bethune.fr/matomo.php',
          siteId: 1,
        },
      ],
      routeTracking: {
        enable: true,
      },
    }),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    FontAwesomeModule,
    MarkdownToHtmlModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
