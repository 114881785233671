import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionsFormationService {
  apiUrl;

  constructor(
    private http: HttpClient
  ) {
    this.apiUrl = environment.apiUrl;
  }

  getSessionsOfFormation(idFormation) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return (this.http.get(`${this.apiUrl}/sessions-formations?formation=${idFormation}&date_start_gte=${today.toISOString()}`))
  }
}
