<div class="content">
  <div class="content-header">
    <div class="content-title">
      Carte <span class="color-primary">Cadeau</span>
    </div>
    <div class="content-subtitle">
      Offrez du bien-être à ceux que vous aimez
    </div>
  </div>
  <div class="costs">
    <div *ngFor="let c of cartes$ | async" class="cost fade-in box-shadow">
      <div class="cost-title">
        {{ c.name }} <span class="cost-price">{{ c.price }}€</span>
      </div>
      <div class="choices">
        <div *ngFor="let choice of c.list_elements">
          <div *ngIf="choice !== 'ou'" class="choice">{{choice}}</div>
          <div *ngIf="choice === 'ou'" class="separator">ou</div>
        </div>
      </div>
      <div class="cost-button">
        <app-button [routerLink]="['/contact']" routerLinkActive="router-link-active"  text="Me contacter"></app-button>
      </div>
    </div>
  </div>
</div>
