import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartesCadeauService {
  apiUrl;

  constructor(
    private http: HttpClient
  ) {
    this.apiUrl = environment.apiUrl;
  }

  getCartes() {
    return (this.http.get(`${this.apiUrl}/cartes-cadeaus`));
  }
}
