import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from 'src/app/core/services/services.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-massage',
  templateUrl: './massage.component.html',
  styleUrls: ['./massage.component.scss']
})
export class MassageComponent implements OnInit {
  idMessage;
  service;
  apiUrl;

  constructor(
    private route: ActivatedRoute,
    private servicesService: ServicesService
  ) { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.idMessage = this.route.snapshot.paramMap.get('id');
    this.getService();
  }

  async getService() {
    this.service = await this.servicesService.getService(this.idMessage).toPromise();
    console.log(this.service);
  }

}
