import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) { }

  getServices() {
    return (this.http.get(`${this.apiUrl}/services`));
  }

  getService(idService) {
    return (this.http.get(`${this.apiUrl}/services/${idService}`));
  }
}
