<nav>
  <div class="navbar">
    <div [routerLink]="['/home']" routerLinkActive="router-link-active" class="navbar-title">
      Renaissance
    </div>
    <button (click)="toogleMenu()" class="hamburger" type="button">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <div class="navbar-list">
      <ul>
        <li *ngFor="let l of list">
          <a href="" [routerLink]="[l.url]" routerLinkActive="router-link-active" >
          {{l.name}}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="isMobileMenuOpen" class="mobile-list">
    <div class="mobile-list-element" *ngFor="let l of list">
      <a (click)="toogleMenu()" href="" [routerLink]="[l.url]" routerLinkActive="router-link-active" >
        {{l.name}}
        </a>
    </div>
  </div>
</nav>
