import { Component, OnInit } from '@angular/core';
import { CartesCadeauService } from '../../core/services/cartes-cadeau.service';

@Component({
  selector: 'app-carte-cadeau',
  templateUrl: './carte-cadeau.component.html',
  styleUrls: ['./carte-cadeau.component.scss']
})
export class CarteCadeauComponent implements OnInit {
  cartes$;
  constructor(
    private cartesCadeauService: CartesCadeauService
  ) { }

  ngOnInit(): void {
    this.getCartes();
  }

  getCartes() {
    this.cartes$ = this.cartesCadeauService.getCartes();
  }

}
