<div class="content">
  <div class="salon">
    <div class="image">
      <img src="assets/img/formation.png">
      <!-- <img src="assets/img/salon.jpg" alt="" /> -->
    </div>
    <div class="salon-text">
      <div class="title">
        Renaissance <span class="color-primary">Formation</span>
      </div>
      <div class="description">
        <p>
          À 5 min de Béthune, en pleine nature, dans un espace dédié à la formation, vous apprendrez differentes techniques de massages énergétiques.
        </p>
        <p>
          Chaque formation se déroule en présentiel (7, 14 ou 21 heures en fonction de la formation) et en formation continue sur livret de suivi de 10 heures afin de parfaire votre pratique.
        </p>
        <p>
          Vous avez 3 mois pour passer votre certification finale.
        </p>
        <p>
          Vous avez la possibilité de venir réviser <b class="uppercase">gratuitement</b> à toutes les autres sessions de formation avant votre certification.
        </p>
        <p>
          Vous aurez accès au groupe privé de <b>Renaissance <span class="color-primary">Formation</span></b> afin de pouvoir échanger entre praticiens et formateur.
        </p>
        <p>Formation individuelle possible selon vos disponibilités.</p>
        <p>Facilite de règlement</p>
          <div><b>Mis à disposition :</b></div>
          <ul>
            <li>Cabine pour vous changer</li>
            <li>Table et chaise de massage</li>
            <li>Huile</li>
            <li>Réfrigérateur et micro onde</li>
            <li>Boissons</li>
          </ul>
      </div>
    </div>
  </div>
  <div class="formations">
    <div class="formations-title">
      Les formations
    </div>
    <div class="formations-description">
      Voici la liste des formations que je propose
    </div>
    <div class="formations-list">
      <div
        *ngFor="let f of (formations$|async)"
        class="formation box-shadow"
        [routerLink]="['/formation', f.id]"
        routerLinkActive="router-link-active"
      >
        <div class="formation-title">
          Formation {{f.name}} <span class="price">{{f.price}}€</span>
        </div>
        <app-button text="Voir le détail"></app-button>
      </div>
    </div>
  </div>
</div>
