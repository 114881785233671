<div [routerLink]="['/massages']" routerLinkActive="router-link-active"  class="back-button">
  <span><</span> Revenir aux services
</div>
<div class="content" *ngIf="service">

  <div class="left-part">
    <img [src]="apiUrl + service.image.url">
  </div>
  <div clas="right-part">
    <div class="title">
      {{service.name}}
    </div>
    <div class="description">
      <p style="white-space:pre-line">{{service.description}}</p>
      <p class="price">{{service.price_sentence}}</p>
      <app-button [routerLink]="['/contact']" routerLinkActive="router-link-active"  text="Me contacter"></app-button>
    </div>
  </div>

</div>
