<div class="content">
  <div class="content-title">
    <div class="head">
      <div class="title">Mes <span class="color-primary">services</span></div>
      <div class="subtitle">
        Voici les services que je propose en consultation. Les consultations se
        font uniquement sur rendez-vous.
      </div>
    </div>
    <div class="bottom">
      <div class="quote box-shadow fade-in">
        <div class="quote-start">"</div>
        <div class="quote-sentence">
          Le plus grand secret pour le bonheur, c'est d'être bien avec soi.
        </div>
        <div class="quote-author">
          Bernard Fontenelle
        </div>
      </div>
    </div>
  </div>
  <div class="massages-list">
    <div
      *ngFor="let s of services$ | async"
      [routerLink]="['/massage', s.id]"
      routerLinkActive="router-link-active"
      class="massage box-shadow fade-in"
    >
      <div class="massage-image">
        <img [src]="apiUrl + s.image.formats.thumbnail.url" />
      </div>
      <div class="massage-title">
        {{ s.name }}
      </div>
    </div>
  </div>
</div>
