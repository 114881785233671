import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AboutComponent } from './pages/about/about.component';
import { CarteCadeauComponent } from './pages/carte-cadeau/carte-cadeau.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FormationComponent } from './pages/formation/formation.component';
import { FormationsComponent } from './pages/formations/formations.component';
import { HomeComponent } from './pages/home/home.component';
import { MassageComponent } from './pages/massage/massage.component';
import { MassagesComponent } from './pages/massages/massages.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'about',
        component: AboutComponent
      },
      {
        path: 'massages',
        component: MassagesComponent
      },
      {
        path: 'massage/:id',
        component: MassageComponent
      },
      {
        path: 'formations',
        component: FormationsComponent
      },
      {
        path: 'formation/:id',
        component: FormationComponent
      },
      {
        path: 'carte-cadeau',
        component: CarteCadeauComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
