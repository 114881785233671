import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  list = [
    {
      name: 'Accueil',
      url: '/home'
    },
    {
      name: 'Présentation',
      url: '/about'
    },
    {
      name: 'Services',
      url: '/massages',
    },
    {
      name: 'Formations',
      url: '/formations'
    },
    {
      name: 'Carte Cadeau',
      url: '/carte-cadeau'
    },
    {
      name: 'Contact',
      url: '/contact'
    }
  ];
  isMobileMenuOpen = false;
  constructor() { }

  ngOnInit(): void {
  }

  toogleMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    console.log(this.isMobileMenuOpen);
  }

}
