import { Component, OnInit } from '@angular/core';
import { FormationsService } from '../../core/services/formations.service';

@Component({
  selector: 'app-formations',
  templateUrl: './formations.component.html',
  styleUrls: ['./formations.component.scss']
})
export class FormationsComponent implements OnInit {
  formations$;
  constructor(
    private formationsService: FormationsService
  ) { }

  ngOnInit(): void {
    this.getFormations();
  }

  getFormations() {
    this.formations$ = this.formationsService.getFormations();
  }

}
