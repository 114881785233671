import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormationsService } from 'src/app/core/services/formations.service';
import { SessionsFormationService } from 'src/app/core/services/sessions-formation.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-formation',
  templateUrl: './formation.component.html',
  styleUrls: ['./formation.component.scss']
})
export class FormationComponent implements OnInit {
  idFormation;
  formation;
  apiUrl;
  sessions$;
  constructor(
    private route: ActivatedRoute,
    private formationsService: FormationsService,
    private sessionsService: SessionsFormationService
  ) { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.idFormation = this.route.snapshot.paramMap.get('id');
    this.getFormation();
    this.getSessions();
  }

  async getFormation() {
    this.formation = await this.formationsService.getFormation(this.idFormation).toPromise();
  }

  getSessions() {
    this.sessions$ = this.sessionsService.getSessionsOfFormation(this.idFormation);
  }

}
