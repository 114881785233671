<div class="home">
  <div class="content">
    <div class="home-title">
      Donnez une nouvelle <span>vie</span> à votre corps...
      <div class="name">Sabine Migliore</div>
      <div class="job">Energéticienne</div>
      <p>
        Apprenez à écouter et prendre soin de votre corps. Je
        vous propose tout un panel de soins et massages énergétiques : Magnétisme, Reiki, Lahochi, Massages Ayurvédique Abhyanga, prénatal et Shiatsu.
      </p>
      <p>Je propose également des formations professionelles certifiantes en massages et soins énergétiques.</p>
      <p class="legal-mention">Tous les soins et massages énergétiques ne s'apparentent à aucune pratique médicale ou paramédicale Occidentale ni à un massage thérapeutique.</p>
      <app-button
        [routerLink]="['/about']"
        routerLinkActive="router-link-active"
        text="En savoir plus"
      ></app-button>
    </div>
    <div class="image fade-in">
      <img src="assets/img/logo.jpg">
      <!-- <img src="assets/img/semaine-voyance.jpg"> -->
      <!-- <img src="assets/img/fond.jpg" /> -->
    </div>
    <div></div>
  </div>
</div>
