<div class="content">
  <div class="content-text">
    <div class="title">
      <div class="content-title">
        Contactez-<span class="color-primary">moi</span>
      </div>
      <div class="content-description">
        <p>
          Veuillez remplir le formulaire pour avoir plus de renseignement et
          reservez votre séance.
        </p>
      </div>
    </div>
    <div class="contact-info">
      <div class="contact-info-box box-shadow">
        <div class="contact-info-title">Mes coordonnées</div>
        <div class="contact-info-content">
          <div class="contact-info-field">
            <div class="contact-info-field-title">
              <fa-icon [icon]="faEnvelope"></fa-icon>
              Email</div>
            <div class="contact-info-field-content">
              renaissancebethune@gmail.com
            </div>
          </div>
          <div class="contact-info-field">
            <div class="contact-info-field-title">
              <fa-icon [icon]="faPhone"></fa-icon>Téléphone</div>
            <div class="contact-info-field-content">06.42.70.65.34</div>
          </div>
          <div class="contact-info-field">
            <div class="contact-info-field-title">
              <fa-icon [icon]="faMarkerAlt"></fa-icon>
              Adresse</div>
            <div class="contact-info-field-content">
              208 Rue du Courant, 62660 BEUVRY
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-form">
    <div class="content-form-title">
      Formulaire de contact
    </div>
    <div class="form">
      <div class="input">
        <label for="firstname">Nom</label>
        <input [(ngModel)]="contact.name" placeholder="Entrez votre nom ..." id="firstname" />
      </div>
      <div class="input">
        <label for="lastname">Téléphone</label>
        <input [(ngModel)]="contact.phone" placeholder="Entrez votre numéro ..." id="lastname" />
      </div>
      <div class="input">
        <label for="lastname">Email</label>
        <input [(ngModel)]="contact.email" placeholder="Entrez votre email ..." id="lastname" />
      </div>
      <div class="input">
        <div class="message-title">Pratique</div>
        <select [(ngModel)]="contact.practice">
          <option *ngFor="let p of (practices$|async)" [value]="p.id">{{p.name}}</option>
        </select>
      </div>
      <div class="message">
        <div class="message-title">Veuillez écrire votre message</div>
        <textarea
        [(ngModel)]="contact.message"
          placeholder="Entrez votre message ..."
          id="lastname"
        ></textarea>
      </div>

      <div class="button">
        <app-button (click)="createContact()" text="Envoyer"></app-button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isMessageSentSucess" class="alert alert-success">Votre message a bien été envoyé</div>
<div *ngIf="isMessageSentError" class="alert alert-error">Désolé votre message n'a pas pu être envoyé. Veuillez remplir tous les champs.</div>
