import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FormationsService {
  apiUrl;
  constructor(
    private http: HttpClient
  ) {
    this.apiUrl = environment.apiUrl;
  }

  getFormations() {
    return (this.http.get(`${this.apiUrl}/formations`));
  }

  getFormation(idFormation) {
    return (this.http.get(`${this.apiUrl}/formations/${idFormation}`))
  }
}
