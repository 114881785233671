import { Component, OnInit } from '@angular/core';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { PracticesService } from '../../core/services/practices.service';
import { ContactsService } from '../../core/services/contacts.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  faPhone;
  faEnvelope;
  faMarkerAlt;
  practices$;
  contact = {
    name: '',
    email: '',
    phone: '',
    practice: 1,
    message: ''
  }
  isMessageSentSucess = false;
  isMessageSentError = false;

  constructor(
    private practicesService: PracticesService,
    private contactsService: ContactsService
  ) {
    this.faPhone = faPhone;
    this.faEnvelope = faEnvelope;
    this.faMarkerAlt = faMapMarkerAlt;
  }

  ngOnInit(): void {
    this.getPractices();
  }

  getPractices() {
    this.practices$ = this.practicesService.getPractices();
  }

  createContact() {
    console.log(this.contact);
    this.contactsService.createContact(this.contact).subscribe(data => {
      this.contact = {
        name: '',
        email: '',
        phone: '',
        practice: 1,
        message: ''
      };
      this.isMessageSentSucess = true;
      setTimeout(() => {
        this.isMessageSentSucess = false;
      }, 5000)
    }, (err) => {
      this.isMessageSentError = true;
      setTimeout(() => {
        this.isMessageSentError = false;
      }, 5000);
    });
  }

}
