<div
  [routerLink]="['/formations']"
  routerLinkActive="router-link-active"
  class="back-button"
>
  <span><</span> Revenir aux formations
</div>
<div class="content" *ngIf="formation">
  <div class="left-part">
    <img [src]="apiUrl + formation.image.url" />
  </div>
  <div clas="right-part">
    <div class="title">
      Formation <span class="color-primary">{{ formation.name }}</span>
    </div>
    <div class="description">
      <div [innerHTML]="formation.description | MarkdownToHtml"></div>
      <p class="price">Tarif : {{ formation.price_sentence }}</p>
      <div class="dates-list">
        <div *ngFor="let s of sessions$ | async" class="date box-shadow">
          <div class="date-title">
            Du {{ s.date_start | date: "dd MMMM" }} au
            {{ s.date_end | date: "dd MMMM yyyy" }}
          </div>
          <div class="date-info">
            <div><span class="bold">Lieu : </span>{{ s.location }}</div>
            <div><span class="bold">Prix : </span>{{ s.price }}€</div>
            <div>
              <span class="bold">Heures de formation : </span>De
              {{ s.hour_start }} à {{ s.hour_end }}
            </div>
          </div>
        </div>
      </div>
      <app-button
        [routerLink]="['/contact']"
        routerLinkActive="router-link-active"
        text="Me contacter"
      ></app-button>
    </div>
  </div>
</div>
