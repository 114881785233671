import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../core/services/services.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-massages',
  templateUrl: './massages.component.html',
  styleUrls: ['./massages.component.scss']
})
export class MassagesComponent implements OnInit {
  services$;
  apiUrl;
  constructor(
    private servicesService: ServicesService
  ) { }

  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.getServices();
  }

  getServices() {
    this.services$ = this.servicesService.getServices();
  }

}
