<div class="content">
  <div class="content-left">
    <div class="content-title">
      Qui-suis-<span class="color-primary">je</span> ?
    </div>
    <div class="content-description">
      <div class="name">Sabine Migliore</div>
      <div class="job">Energéticienne</div>
      <p>J’ai depuis toute petite eu conscience des dons qui m’ont été transmis. J’ai utilisé par passion ces capacités uniquement dans un cadre amical et familial pendant des années ; ayant eu dans le même temps une carrière dans le domaine commercial.</p>
      <p>Atteinte de Fibromyalgie, diagnostiquée en 2014, j’utilise les énergies : Magnétisme, Lahochi, Reiki qui m’apportent un réel bien être face à ce syndrome. Etant appelée à aider les autres au plus profond de moi depuis longtemps, je décide de mettre mon savoir au profit de ceux qui en ont besoin.
      </p>
    </div>
  </div>
  <div class="content-right">
    <div class="content-image">
      <img src="assets/img/sabine.jpg">
    </div>
  </div>

</div>
